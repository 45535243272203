
 * {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.main {
  width: 100%;
  height: 100vh;
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

h1 {
  font-size: 24px;
  text-align: center;
}

.countdown {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.countdown-item {
  text-align: center;
  margin: 0 5px; /* Adjust spacing between values */
}
.countdown-label{
  font-weight: bolder;
  color: black;
}
.countdown-value {
  font-size: 18px;
  padding: 8px;
  margin: 0;
  background-color: rgba(51, 51, 51, 0.5);
  color: white;
  
  border-radius: 8px;
}

/* Mobile Styles */
@media only screen and (max-width: 480px) {
  body {
    overflow: hidden;
  }

  .main {
    height: 100vh;
  }

  .background-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .content {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }

  .countdown {
    flex-direction: row; /* Display values in a column on mobile */
    align-items: center;
    margin-top: 10px;
  }
  .countdown-label{
    font-weight: bolder;
    color: black;
  }
  .countdown-item {
    margin-bottom: 10px;
    margin: 10px 0; /* Adjust spacing between values for mobile */
  }

  .countdown-value {
    font-size: 18px;
    padding: 8px;
    margin: 0;
  }
}
